import React from 'react';
import { Toast, ToastProps } from 'primereact/toast';
import { SnackbarV2Style as S } from './SnackbarV2.style';
import { PButtonIcon } from 'components/prime/PButton/PButtonIcon';
import { HStack } from 'components/layouts/primitives/Stack/Stack';
import { Box } from 'components/layouts/primitives/Box/Box';
import { Text } from 'components/Text/Text';
import { PButtonLink } from 'components/prime/PButton/PButtonLink';
import { Icon } from 'components/Icon/Icon';

export type SnackbarV2MessageCustomProps = {
  title: string;
  onDismiss: () => void;
  leftItem?: React.ReactNode;
  description?: string;
  actionLabel?: string;
  actionOnClick?: () => void;
  dismissable?: boolean;
};

export const SnackbarV2MessageCustom: React.FC<SnackbarV2MessageCustomProps> = ({
  title,
  onDismiss,
  leftItem,
  description,
  actionLabel,
  actionOnClick,
  dismissable,
}) => {
  return (
    <S.SnackbarMessage backgroundColor="neutral800" padding="paddingMD" radius="roundedMD" shadow="shadowMD">
      <HStack gap="gap2" align="flex-start" splitAfterIdx={leftItem ? 1 : 0}>
        {leftItem && (
          <HStack align="center" justify="center" className="snackbar-message-left-item">
            {leftItem}
          </HStack>
        )}
        <Box padding={['paddingNone', 'paddingSM']} noAutoMinSize>
          <HStack gap="gap4" align="flex-start">
            <Text
              as="p"
              size="fontSizeSM"
              lineHeight="lineHeightMD"
              weight="medium"
              color="neutral0"
              ellipsis
              fit={description ? 'content' : 'space'}
            >
              {title}
            </Text>
            {!!description && (
              <Text
                as="p"
                size="fontSizeSM"
                lineHeight="lineHeightMD"
                weight="regular"
                color="neutral400"
                ellipsis
              >
                {description}
              </Text>
            )}
          </HStack>
        </Box>
        {!!actionLabel && (
          <Box fit="content">
            <PButtonLink
              severity="invert"
              label={actionLabel}
              onClick={() => {
                actionOnClick?.();
                onDismiss();
              }}
            />
          </Box>
        )}
        {dismissable && (
          <Box fit="content">
            <PButtonIcon
              icon={<Icon icon="xLine" />}
              severity="primary"
              size="xs"
              shape="squircle"
              onClick={onDismiss}
            />
          </Box>
        )}
      </HStack>
    </S.SnackbarMessage>
  );
};

export const SnackbarV2 = React.forwardRef<Toast, ToastProps>((_, ref) => {
  return (
    <S.Snackbar
      appendTo={window.document.body}
      transitionOptions={{ classNames: 'snackbar-anim' } as any}
      position="bottom-center"
      ref={ref}
    />
  );
});
