import styled from 'styled-components';
import { Toast } from 'primereact/toast';
import { colours } from 'styles/styles';

export const SnackbarStyle = {
  Snackbar: styled(Toast)`
    opacity: 1;
    bottom: 6px;
    width: calc(100vw - 48px);
    max-width: 550px;
    .p-toast-message {
      margin-bottom: 18px;
      box-shadow: none;
      border-radius: unset;

      .p-toast-message-content {
        all: unset;
        & > div:nth-child(2) {
          display: none;
        }
      }

      /* Custom Toast Animation */
      &.snackbar-anim-enter {
        opacity: 0;
        transform: translateY(100%);
      }
      &.snackbar-anim-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.snackbar-anim-exit {
        opacity: 1;
        transform: translateY(0);
      }
      &.snackbar-anim-exit-active {
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  `,
  SnackbarMessage: styled.div`
    background-color: ${colours.neutralCharcoal};
    border-radius: 4px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    .snackbar-message-custom-icon {
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-info path {
        fill: ${colours.uiAction};
      }
      .icon-success path {
        fill: ${colours.uiSuccess};
      }
      .icon-error path {
        fill: ${colours.uiDanger};
      }
      .icon-warning path {
        fill: ${colours.uiWarning};
      }
    }
    .snackbar-message-custom-content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      color: ${colours.neutralWhite};
      & > .title {
        font-weight: 500;
        line-height: 22.5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      & > .label {
        font-size: 13px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .snackbar-message-custom-actions {
      flex: 0 0 fit-content;
      width: fit-content;
    }
  `,
  Actions: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
};
