import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { Button, ButtonProps } from 'primereact/button';
import { PButtonLinkStyle as S } from './PButtonLink.style';

type PButtonLinkProps = Omit<ButtonProps, 'severity' | 'size' | 'icon' | 'label' | 'loading'> & {
  label?: React.ReactNode;
  severity?: 'primary' | 'secondary' | 'invert';
  icon?: React.ReactNode;
};

export const PButtonLink = React.forwardRef<Button, PButtonLinkProps>((props, ref) => {
  const classNames = useClassNames(
    {
      [`button-link-severity-${props.severity || 'primary'}`]: true,
    },
    props.className
  );
  const cleanProps = useMemo(() => (({ severity, label, icon, ...p }) => p)(props), [props]);
  return (
    <S.ButtonLink type="button" {...cleanProps} className={classNames} ref={ref}>
      <div className="button-link-custom-wrapper">
        {props.children || (
          <>
            <span className="button-link-custom-label">{props.label}</span>
            {props.icon && <span className="button-link-custom-icon-wrapper">{props.icon}</span>}
          </>
        )}
      </div>
    </S.ButtonLink>
  );
});
