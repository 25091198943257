import React from 'react';
import { useInitBloc } from 'hooks/useInitBloc';
import { useIsReactPage } from 'hooks/useIsReactPage';
import { BLoCBase, BLoCParams, IBLoCInitialisable, renderBlocChild } from 'types/BLoCBase';
import { NotificationServiceTypes } from './NotificationService.types';
import { popupRef } from './components/NotificationPopup/NotificationPopup';
import { NotificationContentLayout } from './components/NotificationContentLayout/NotificationContentLayout';
import { PButton } from 'components/prime/PButton/PButton';
import { $get } from 'services/api';
import { pusher } from 'services/pusher';
import { toast, toastClear } from 'services/toast';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Observable, filter, first } from 'rxjs';
import { snackbar } from 'services/snackbar';

const notification = (props: NotificationServiceTypes.NotificationProps) => {
  popupRef?.current?.replace(
    Object.assign({
      position: 'bottom-right',
      className: '',
      sticky: true,
      detail: <NotificationContentLayout {...props} />,
    })
  );
};

type State = {
  userProjectAccessMap: Record<string, boolean | undefined>;
};

class BLoC extends BLoCBase<State> implements IBLoCInitialisable {
  constructor(private navigate: NavigateFunction, private isReactPage: boolean) {
    super({ userProjectAccessMap: {} });
  }

  public onInit = () => {
    this.initThemeCountingCompletedChannel();
    this.initAugmentedDataNotifications();
    this.initIngestionCompleteChannel();
  };

  private initAugmentedDataNotifications = () => {
    const MSG_MI = '.AdProjectTemplateMarketInsightGenerated';
    const MSG_AUDIENCE = '.AdProjectTemplateAudienceQuestionsGenerated';
    const MSG_QUESTIONS = '.AdProjectTemplateQuestionsGenerated';
    const MSG_COMPLETED = '.AdProjectTemplateCompleted';
    [MSG_MI, MSG_AUDIENCE, MSG_QUESTIONS, MSG_COMPLETED].forEach((message) => {
      this.addSub(
        pusher
          .$listen<{
            customerId: string;
            templateId: number;
            projectId: string;
            projectName: string;
          }>(message)
          .pipe(this.checkUserAccessToProjectOperator((event) => event.projectId))
          .subscribe((event) => {
            const notificationDisabled =
              (message === MSG_MI && document.location.pathname.includes('/insights/market-insights')) ||
              ([MSG_AUDIENCE, MSG_QUESTIONS, MSG_COMPLETED].includes(message) &&
                document.location.pathname.includes('/insights/virtual-audiences'));
            if (!notificationDisabled) {
              snackbar({
                severity: 'success',
                title: event.projectName,
                label:
                  message === MSG_AUDIENCE
                    ? 'Your virtual audience is ready to view.'
                    : message === MSG_QUESTIONS
                    ? 'Your questions are now ready to view.'
                    : message === MSG_MI
                    ? 'Market insights are ready to view.'
                    : 'Responses are successfully generated.',
                action: {
                  label: 'Go to project',
                  onClick: () => {
                    const targetPath = `/projects/${event.projectId}/insights/${
                      message === MSG_MI ? 'market-insights' : 'virtual-audiences'
                    }`;
                    this.isReactPage
                      ? this.navigate(targetPath)
                      : (window.location.href = window.location.origin + targetPath);
                  },
                },
                life: 8000,
              });
            }
          }),
        `${message}Notification`
      );
    });
  };

  private initThemeCountingCompletedChannel = () => {
    this.addSub(
      pusher
        .$listen<{
          surveyQuestionId: string;
          exploreId: string | null;
          customerId: string;
          projectId: string;
          exploreName: string | null;
        }>('.ThemeCountingCompleted')
        .pipe(this.checkUserAccessToProjectOperator((event) => event.projectId))
        .subscribe((event) => {
          event?.exploreId &&
            toast({
              severity: 'success',
              summary: 'Counting complete',
              detail: (
                <div>
                  We’ve finished counting your data. Explore your themes and sub-themes now.
                  {event.exploreId ? (
                    <PButton
                      label="Explore data"
                      severity="tertiary"
                      size="sm"
                      className="mt-3 mb-1"
                      onClick={() => {
                        toastClear();
                        this.isReactPage
                          ? this.navigate(`/hey-yabble/explore/${event.exploreId}`)
                          : (window.location.href = `${window.location.origin}/hey-yabble/explore/${event.exploreId}`);
                      }}
                    />
                  ) : null}
                </div>
              ),
              sticky: true,
            });
        }),
      'themeCountingCompletedChannel'
    );
  };

  private initIngestionCompleteChannel = () => {
    this.addSub(
      pusher
        .$listen<{
          ingestionId: string;
          projectId: string;
          customerId: string;
          ingestionName: string;
          projectName: string;
          hasTc: boolean;
          result: 'ready' | 'failed';
        }>('.IngestionComplete')
        .pipe(
          filter((event) => event.result === 'ready'),
          this.checkUserAccessToProjectOperator((event) => event.projectId)
        )
        .subscribe((ingestion) => {
          notification({
            title: 'Dive into game-changing insights',
            description: ingestion.hasTc ? (
              <>
                Your import <b>{ingestion.ingestionName}</b> is complete! Your Count will be ready to explore
                in 30 mins - 2 hours depending on the size of your data.
                <br />
                <br />
                In the meantime, ask unlimited questions, dive deeper into emerging trends or focus on a
                particular topic by chatting with Gen, your research assistant.
              </>
            ) : (
              <>
                Your import <b>{ingestion.ingestionName}</b> is complete! Ask unlimited questions, dive deeper
                into emerging trends or focus on a particular topic by chatting with Gen, your research
                assistant.
              </>
            ),
            actionButtons: (
              <div className="import-notification-action-buttons">
                <PButton label="Close" onClick={() => popupRef?.current?.clear()} severity="secondary" />
                <PButton
                  label="Chat with Gen"
                  onClick={() => {
                    popupRef?.current?.clear();
                    this.isReactPage
                      ? this.navigate(`/projects?chatModalOpen=true&projectId=${ingestion.projectId}`)
                      : (window.location.href = `${window.location.origin}/projects?chatModalOpen=true&projectId=${ingestion.projectId}`);
                  }}
                />
              </div>
            ),
          });
        }),
      'ingestionCompleteChannel'
    );
  };

  private checkUserAccessToProjectOperator =
    <S extends object>(projectIdGetter: (value: S) => string) =>
    (source: Observable<S>) => {
      return new Observable<S>((subscriber) => {
        return source.subscribe({
          next: (value) => {
            const currentProject = this.currentState('userProjectAccessMap')[projectIdGetter(value)];
            if (currentProject === undefined) {
              if (value && projectIdGetter(value)) {
                $get(`projects/${projectIdGetter(value)}`)
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      subscriber.next(value);
                      this.mutateState('userProjectAccessMap', (prev) => ({
                        ...prev,
                        [`${projectIdGetter(value)}`]: true,
                      }));
                    },
                    error: () => {
                      this.mutateState('userProjectAccessMap', (prev) => ({
                        ...prev,
                        [`${projectIdGetter(value)}`]: false,
                      }));
                    },
                  });
              }
            } else {
              currentProject
                ? subscriber.next(value)
                : console.error('Notification Service: current user has no access to project');
            }
          },
          error: (error) => subscriber.error(error),
          complete: () => subscriber.complete(),
        });
      });
    };
}

const Context = React.createContext<Readonly<BLoC>>({} as any);

export const NotificationServiceBLoC: React.FC<BLoCParams<BLoC, State>> = ({ children }) => {
  const navigate = useNavigate();
  const isReactPage = useIsReactPage();
  const bloc = useInitBloc(() => new BLoC(navigate, isReactPage));
  return bloc ? <Context.Provider value={bloc}>{renderBlocChild(children, bloc)}</Context.Provider> : null;
};
