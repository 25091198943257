import { map, shareReplay } from 'rxjs';
import { BLoCBase } from 'types/BLoCBase';
import { $get } from 'services/api';
import { useObservableState } from 'observable-hooks';
import { jwtDecode } from 'jwt-decode';

type FeatureFlags =
  | 'ff-theme-trending'
  | 'ff-qual'
  | 'ff-stories'
  | 'ff-exec-sum'
  | 'ff-chat'
  | 'ff-mandatory-2fa'
  | 'ff-hy-query'
  | 'ff-sago-trial'
  | 'ff-tc-pipeline-selection';

class FFStore extends BLoCBase<{ scopes: string[] }> {
  public readonly $ffs = $get<string[]>('regions').pipe(shareReplay());
  public init = (scopes: FeatureFlags[]) => {
    this.setState(
      'scopes',
      scopes.filter((scope) => scope.startsWith('ff-'))
    );
  };
  constructor() {
    super({
      scopes:
        import.meta.env.DEV && import.meta.env.REACT_APP_ACCESS_TOKEN
          ? (jwtDecode(import.meta.env.REACT_APP_ACCESS_TOKEN) as any)?.scopes?.filter((scope: any) =>
              scope.startsWith('ff-')
            ) || []
          : [],
    });
  }

  public hasFF = (scope: FeatureFlags) => this.currentState('scopes')?.includes(scope) || false;
  public $hasFF = (scope: FeatureFlags) =>
    this.$getState('scopes').pipe(map((scopes) => (scopes?.length ? scopes.includes(scope) : false)));
}

let store: Readonly<FFStore> | null = null;

export const useHasFF = (ff: FeatureFlags, def: boolean | null = false) =>
  useObservableState(getFFStore().$hasFF(ff), def);

export const getFFStore = () => {
  if (!store) {
    store = Object.freeze(new FFStore());
  }
  return store;
};
