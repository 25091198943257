import styled from 'styled-components';
import { Toast } from 'primereact/toast';

import { colours } from 'styles/styles';

export const NotificationPopupStyle = {
  PopUp: styled(Toast)`
    width: 320px;
    opacity: 1;
    bottom: 24px;
    z-index: 999 !important;

    & > div > .p-toast-message {
      background-color: ${colours.neutralWhite} !important;
      opacity: 1;
      box-shadow: 0px 2px 12px 0px rgba(24, 30, 38, 0.12);
      border-radius: 12px;
      border-left-width: 0px !important;
      margin: 0;
      overflow: hidden;

      .p-toast-message-content {
        display: block;
        padding: 0;
        .p-toast-message-icon {
          display: none;
        }
        .p-toast-message-text {
          margin: 0;
          padding-left: 0;
          & > .p-toast-summary {
            display: none;
          }
          & > .p-toast-detail {
            font-size: 14px;
            line-height: 22px;
            margin: 0;
            color: ${colours.neutralBody};
          }
        }
      }

      .p-toast-message-icon {
        display: none;
      }
      .p-toast-icon-close {
        display: none;
      }

      /* Custom Toast Animation */
      &.toast-anim-enter {
        opacity: 0;
        transform: translateX(100%);
      }
      &.toast-anim-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.toast-anim-exit {
        opacity: 1;
        transform: translateX(0);
      }
      &.toast-anim-exit-active {
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 300ms, transform 300ms;
        transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
    }
  `,
};
