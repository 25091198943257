import styled, { css } from 'styled-components';
import { gap } from 'styles/styles';

export const StackStyle = {
  Stack: styled.div<{ $props: any }>`
    ${({ $props }) => css`
      display: ${$props.inline ? 'inline-flex' : 'flex'};
      flex-direction: ${$props.direction || 'column'};
      justify-content: ${$props.justify || 'flex-start'};
      align-items: ${$props.align || 'stretch'};
      gap: ${$props.gap ? (gap as any)[$props.gap] : '0px'};
      flex-wrap: ${$props.wrap || 'nowrap'};
      ${$props.noAutoMinSize ? 'min-width: 0;' : ''}
      ${$props.stretch ? 'height: 100%;' : ''}
      ${$props.fit ? `flex: ${$props.fit === 'content' ? '0 0 fit-content' : '1 1 auto'};` : ''}
      ${typeof $props.splitAfterIdx == 'number'
        ? css`
        & > :nth-child(${$props.splitAfterIdx + 2}) {
          margin-${$props.direction?.includes('column') ? 'top' : 'left'}: auto;
        }
      `
        : ''}
    `};
  `,
};
