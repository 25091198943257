import { createGlobalStyle } from 'styled-components';
import { borderRadius, borders, colourPalette, colours, hexToRGBA, padding } from './styles';

export const ConfirmDialogStyle = createGlobalStyle`
    .p-dialog-mask.p-component-overlay.p-dialog-visible > .p-dialog.p-component.p-confirm-dialog {
        width: 400px;
        border-radius: 0px 0px 12px 12px;
        .p-dialog-header {
            .p-dialog-title{
                font-weight: 500;
                font-size: 16px;
                color: ${colours.neutralCharcoal}
            }
            padding: 0px 24px;    
            border-radius: 12px 12px 0px 0px;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid ${colours.neutralGrey03};
            & > .p-dialog-header-icons {
                display: none;
            } 
        }
       
        & > .p-dialog-content {
            padding: 24px 24px 32px 24px;
             & >  .p-confirm-dialog-message {
                line-height: 22px;
                color: ${colours.neutralLightCharcoal};
            }
            
        }
        & > .p-dialog-footer {
            padding: 24px;
            padding-top: 8px;
            border-radius:  0px 0px 12px 12px;
            button {
              height: 40px;
              margin-right: 8px;
              padding: 0 ${padding.paddingSM};
              border-radius: ${borderRadius.roundedSM};
              background: ${colourPalette.neutral100} !important;
              &:last-child {
                margin: 0;
              }
              .p-button-label {
                color: ${colourPalette.neutral900} !important;
                font-weight: 500;
              }
              &:focus,
              &:focus-within,
              &:focus-visible {
                background: ${colourPalette.neutral100} !important;
                outline: ${borders.borderMD} solid ${colourPalette.neutral0};
                box-shadow: 0px 0px 0px 4px ${hexToRGBA(colourPalette.blue600, 0.3)};
              }
              &:hover {
                background: ${colourPalette.neutral200} !important;
              }
              &:active {
                background: ${colourPalette.neutral50} !important;
              }

              &.p-confirm-dialog-accept {
                background: ${colourPalette.blue500} !important;
                .p-button-label {
                  color: ${colourPalette.neutral0} !important;
                }
                &:focus,
                &:focus-within,
                &:focus-visible {
                  background: ${colourPalette.blue500} !important;
                }
                &:hover {
                  background: ${colourPalette.blue600} !important;
                }
                &:active {
                  background: ${colourPalette.blue400} !important;
                }
              }

              &.p-button-danger {
                background: ${colourPalette.red50} !important;
                .p-button-label {
                  color: ${colourPalette.rose600} !important;
                }
                &:focus,
                &:focus-within,
                &:focus-visible {
                  background: ${colourPalette.red50} !important;
                }
                &:hover {
                  background: ${colourPalette.red200} !important;
                }
                &:active {
                  background: ${colourPalette.red50} !important;
                }
              }
            }
        }
    }
`;
