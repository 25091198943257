import { ConfirmDialog } from 'primereact/confirmdialog';
import styled from 'styled-components';

export const PConfirmDialogStyle = {
  ConfirmDialog: styled(ConfirmDialog)`
    & > .p-confirm-dialog {
      background-color: red !important;
    }
  `,
};
