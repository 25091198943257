import React, { useMemo } from 'react';
import { useClassNames } from 'hooks/useClassNames';
import { Button, ButtonProps } from 'primereact/button';
import { PButtonStyle as S } from './PButton.style';
import { Spinner } from 'components/Spinner/Spinner';

export type PButtonProps = Omit<ButtonProps, 'severity' | 'size' | 'icon' | 'label'> & {
  label?: React.ReactNode;
  severity?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'soft'
    | 'ghost'
    | 'invert'
    | 'danger'
    | 'highlight'
    | 'on-invert';
  size?: 'sm' | 'md' | 'lg';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
};

export const PButton = React.forwardRef<Button, PButtonProps>((props, ref) => {
  const classNames = useClassNames(
    {
      [`button-size-${props.size || 'md'}`]: true,
      [`button-severity-${props.severity || 'primary'}`]: true,
    },
    props.className
  );
  const cleanProps = useMemo(
    () => (({ severity, size, label, iconLeft, iconRight, ...p }) => p)(props),
    [props]
  );
  return (
    <S.Button type="button" {...cleanProps} className={classNames} ref={ref} loadingIcon={<Spinner />}>
      <div className="button-custom-wrapper">
        {props.children || (
          <>
            {props.iconLeft && <span className="button-custom-icon-wrapper">{props.iconLeft}</span>}
            <span className="button-custom-label">{props.label}</span>
            {props.iconRight && <span className="button-custom-icon-wrapper">{props.iconRight}</span>}
          </>
        )}
      </div>
    </S.Button>
  );
});
