import styled, { css } from 'styled-components';
import { Menu } from 'primereact/menu';
import { colours } from 'styles/styles';
import chevronRightSvg from 'assets/svg/chevron-right.svg';

export const MenuBaseStyle = css`
  padding: 4px 0;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  border: none;
  width: fit-content;
  min-width: 160px;
  max-width: 240px;
  .p-menu-list,
  .p-submenu-list,
  & > ul[role='menubar'] {
    background: ${colours.neutralWhite};
    padding: 4px 0;
    border: 1px solid ${colours.neutralCharcoalAlpha08};
    box-shadow: 0px 4px 8px 0px ${colours.neutralCharcoalAlpha08};
    border-radius: 8px;
  }
  .p-submenu-header {
    padding: 4px 16px;
    color: ${colours.neutralGrey05};
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-transform: capitalize;
  }
  .p-menuitem {
    padding: 0 4px;
    .p-menuitem-link {
      padding: 8px 8px 8px 12px;
      border-radius: 4px;
      gap: 4px;
      svg {
        flex: 0 0 24px;
        path {
          &[fill] {
            fill: ${colours.neutralLightCharcoal};
          }
          &[stroke] {
            stroke: ${colours.neutralLightCharcoal};
          }
        }
      }
      .p-menuitem-text {
        flex: 1 1 auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        color: ${colours.neutralLightCharcoal};
      }
      &:focus {
        background: transparent;
        box-shadow: inset 0px 0px 0px 2px ${colours.uiAction};
      }
      &:hover {
        background: rgba(240, 240, 240, 0.8);
        svg {
          path {
            &[fill] {
              fill: ${colours.neutralCharcoal};
            }
            &[stroke] {
              stroke: ${colours.neutralCharcoal};
            }
          }
        }
        .p-menuitem-text {
          color: ${colours.neutralCharcoal};
        }
      }
      &.p-disabled {
        opacity: 1;
        svg {
          path {
            &[fill] {
              fill: ${colours.neutralGrey04};
            }
            &[stroke] {
              stroke: ${colours.neutralGrey04};
            }
          }
        }
        .p-menuitem-text {
          color: ${colours.neutralGrey04};
        }
        &:focus {
          background: transparent;
          box-shadow: none;
        }
      }
    }
    &.destructive {
      .p-menuitem-link {
        svg {
          path {
            &[fill] {
              fill: ${colours.uiDanger};
            }
            &[stroke] {
              stroke: ${colours.uiDanger};
            }
          }
        }
        .p-menuitem-text {
          color: ${colours.uiDanger};
        }
        &:focus {
          box-shadow: inset 0px 0px 0px 2px ${colours.uiDanger};
        }
        &:hover {
          background: ${colours.uiDangerDisabled};
          svg {
            path {
              &[fill] {
                fill: ${colours.uiDanger};
              }
              &[stroke] {
                stroke: ${colours.uiDanger};
              }
            }
          }
          .p-menuitem-text {
            color: ${colours.uiDanger};
          }
        }
        &.p-disabled {
          svg {
            path {
              &[fill] {
                fill: ${colours.uiDangerDisabled};
              }
              &[stroke] {
                stroke: ${colours.uiDangerDisabled};
              }
            }
          }
          .p-menuitem-text {
            color: ${colours.uiDangerDisabled};
          }
        }
      }
    }
    &.with-chevron {
      .p-menuitem-link {
        &::after {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          flex: 0 0 24px;
          mask-image: url(${chevronRightSvg});
          mask-size: auto;
          mask-position: center;
          mask-repeat: no-repeat;
          background-color: ${colours.neutralLightCharcoal};
          margin-left: auto;
        }
        &:hover {
          &::after {
            background-color: ${colours.neutralCharcoal};
          }
        }
        &.p-disabled {
          opacity: 1;
          &::after {
            background-color: ${colours.neutralGrey04};
          }
        }
      }
    }
    &.footer {
      padding: 0px;
      .p-menuitem-link {
        padding: 4px 8px 4px 16px;
        flex-direction: row-reverse;
        gap: 8px;
        svg {
          path {
            &[fill] {
              fill: ${colours.neutralGrey06};
            }
            &[stroke] {
              stroke: ${colours.neutralGrey06};
            }
          }
        }
        .p-menuitem-text {
          color: ${colours.neutralGrey06};
        }
        &:focus {
          box-shadow: none;
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
  .p-menu-separator {
    margin: 4px 0;
    border-top: 1px solid ${colours.neutralCharcoalAlpha08};
  }
  &.p-menu-overlay,
  &.p-tieredmenu-overlay {
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const PMenuStyle = {
  Menu: styled(Menu)<{ $width?: number }>`
    ${MenuBaseStyle}
    ${({ $width }) =>
      typeof $width === 'number'
        ? css`
            width: ${$width}px;
          `
        : ''}
  `,
};
