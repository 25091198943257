import styled from 'styled-components';
import { colourPalette } from 'styles/styles';

export const SpinnerStyle = {
  Spinner: styled.svg<{ $size?: number; $colour?: string }>`
    background: none;
    display: block;
    shape-rendering: auto;
    width: ${({ $size }) => `${$size || 24}px`};
    height: ${({ $size }) => `${$size || 24}px`};

    rect {
      fill: ${({ $colour }) => $colour || colourPalette.neutral900};
    }
  `,
};
